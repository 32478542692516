/* global AboutUsPage */
class AboutUsPageCustom extends AboutUsPage {

    getTemplate() {
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="container">
                      <div class="section-main mb-4">
                          <h5 class="text-center">La empresa</h5>
                          <hr>
                          <div class="row">
                              <div class="col-12 col-md-6">
                                  <p>Durante la última década Ofidirect ha evolucionado de ser un proveedor de insumos de oficina a ser un socioestratégico en las necesidades diarias del negocio de nuestros clientes.</p>
                                  <p>Esto significa que cuando decide trabajar con Ofidirect, su empresa automáticamente gana un socio, con una gran infraestructura que lo beneficiará con los precios más bajos del mercado, ya que aprovechará el gran poder de negociación de Ofidirect con sus proveedores.</p>
                                  <p>Por otro lado, accederá a una red logística que le permitirá ser abastecido en cualquier punto del país. Y toda la tecnología para que pueda realizar sus pedidos con facilidad y en el momento en que usted lo desee.</p>
                              </div>
                              <div class="col-12 col-md-6 text-center text-md-left">
                                  <img class="img-fluid" src="../static/ecommerce/img/ofi_015.jpg">
                              </div>
                          </div>
                          <div class="mt-2 mb-4">
                              <strong>Nuestra misión</strong>
                              <p class="m-0">Ser una empresa con rentabilidad sustentable, a partir de la comercialización y distribución de soluciones, tanto para empresas como para sus empleados, manteniendo altos niveles éticos y ofreciendo un servicio con los mayores niveles de calidad del mercado.</p>
                          </div>
                          <div class="row">
                              <div class="col-12 col-md-6 text-right">
                                  <img class="img-fluid" src="../static/ecommerce/img/ofi_010.jpg">
                              </div>
                              <div class="col-12 col-md-6 text-center text-md-left">
                                  <strong class="d-block">Nuestros valores</strong>
                                  <i>Ética</i>
                                  <p>Creemos en el valor de la ética, en hacer lo correcto, lo que corresponde, con transparencia, buenas intenciones y principios.</p>
                                  <i>Compromiso</i>
                                  <p>Nos comprometemos con la tarea y con el trabajo. Cumplimos con nuestra palabra.</p>
                                  <i>Franqueza y Transparencia</i>
                                  <p>Nos manejamos con la verdad y somos transparentes.</p>
                                  <i>Calidad en lo Humano</i>
                                  <p>Nos preocupa, nos interesa y nos esforzamos por hacer sentir bien al compañero de trabajo, al proveedor y al cliente.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`;
    }
}

AboutUsPageCustom.registerComponent();